import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Cost of Living Payments`}</h2>
    <p>{`You may be entitled to extra payments if you are entitled to certain benefits or tax credits for instance:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Cost of Living payment (£650) if you qualify because you are in receipt of a low income benefit or tax credits i.e. Universal Credit, Job Seekers Allowance, Employment and Support Allowance, Income Support, Pension Credit, Child Tax Credit.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Disability Cost of Living payment (£150) if you get a qualifying disability benefit i.e. Attendance Allowance, Constant Attendance Allowance, DLA, PIP, War Pension Mobility Supplement.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pensioner Cost of Living payment (£300) if you are entitled to a Winter Fuel payment`}</p>
      </li>
    </ul>
    <p>{`For more information check the UK Government website on the
`}<a parentName="p" {...{
        "href": "https://www.gov.uk/guidance/cost-of-living-payment?_ga=2.18817721.1088495452.1667738940-1138477334.1667738940"
      }}>{`Cost of Living Payment`}</a>{`.`}</p>
    <h2>{`Council Tax Rebate 2022/2023`}</h2>
    <p>{`If you live in Bands A-D in England you should get £150 back from your Council Tax bill but you need to pay the bill by direct debit otherwise you may not receive this rebate. For more information check the UK Government website's `}<a parentName="p" {...{
        "href": "https://helpforhouseholds.campaign.gov.uk/housing-support/"
      }}>{`Household Costs`}</a>{` page.`}</p>
    <h2>{`Household Support Fund`}</h2>
    <p>{`There is £1.5 billion that has been made available to support the most vulnerable with essential daily costs such as energy bills, you should contact your Local Authority to see if you are eligible for this fund. In addition, the Local Authorities can issue food vouchers to families during the school holidays. For more information check the UK Government website's `}<a parentName="p" {...{
        "href": "https://helpforhouseholds.campaign.gov.uk/housing-support/"
      }}>{`Household Costs`}</a>{` page.`}</p>
    <h2>{`Budgeting Loans`}</h2>
    <p>{`You may be eligible for a budgeting loan if you have been in receipt of certain benefits for 6 months which can be used for household items, rent etc. For more information visit the UK Government website page on `}<a parentName="p" {...{
        "href": "https://www.gov.uk/budgeting-help-benefits?_ga=2.201242158.1076452750.1667738942-1138477334.1667738940"
      }}>{`Budgeting Loans`}</a>{`.`}</p>
    <h2>{`Cheaper phone and Broadband`}</h2>
    <p>{`You may be eligible to move onto a social tariff if you are struggling with the payment of these services. For more information read `}<a parentName="p" {...{
        "href": "https://www.ofcom.org.uk/phones-telecoms-and-internet/advice-for-consumers/costs-and-billing/social-tariffs"
      }}>{`Ofcom's advice on Social Tariffs`}</a>{`.`}</p>
    <h2>{`Energy Bills Support Scheme Discount`}</h2>
    <p>{`You may be able to get up to £400 discount support this winter. For more information visit the UK Government page on `}<a parentName="p" {...{
        "href": "https://www.gov.uk/guidance/getting-the-energy-bills-support-scheme-discount?_ga=2.198506031.1076452750.1667738942-1138477334.1667738940"
      }}>{`Getting the Energy Bills Support Scheme Discount
`}</a>{`.`}</p>
    <h2>{`Assistance Paying Water Bills`}</h2>
    <p>{`Many water companies run hardship schemes or fund independent charitable trusts which can help you pay your bills. For more information visit `}<a parentName="p" {...{
        "href": "https://www.ccwater.org.uk/households/help-with-my-bills/"
      }}>{`CCWaters Help With My Bills`}</a>{` page.`}</p>
    <h2>{`Assistance with Rent Payments`}</h2>
    <p>{`You may be able to get help with all or part of your rent. There is no set amount of housing costs support and what you will get will depend on whether you rent privately or from a council or housing association. For more information view the UK Government website's `}<a parentName="p" {...{
        "href": "https://www.gov.uk/benefits-calculators?_ga=2.193978029.1076452750.1667738942-1138477334.1667738940"
      }}>{`Benefits Calculators`}</a>{` page.`}</p>
    <h1>{`Benefits – Are you claiming all that you can claim? Check the below links to see if you are eligible for the following:`}</h1>
    <ul>
      <li parentName="ul">
        <h4 parentName="li"><a parentName="h4" {...{
            "href": "https://www.gov.uk/universal-credit?_ga=2.261619085.1076452750.1667738942-1138477334.1667738940"
          }}>{`Universal Credit`}</a></h4>
      </li>
      <li parentName="ul">
        <h4 parentName="li"><a parentName="h4" {...{
            "href": "https://www.gov.uk/housing-benefit?_ga=2.260945293.1076452750.1667738942-1138477334.1667738940"
          }}>{`Housing Benefit`}</a></h4>
      </li>
      <li parentName="ul">
        <h4 parentName="li"><a parentName="h4" {...{
            "href": "https://www.gov.uk/government/publications/claiming-discretionary-housing-payments/claiming-discretionary-housing-payments?_ga=2.265198735.1076452750.1667738942-1138477334.1667738940"
          }}>{`Discretionary Housing Payment`}</a></h4>
      </li>
      <li parentName="ul">
        <h4 parentName="li"><a parentName="h4" {...{
            "href": "https://www.gov.uk/support-for-mortgage-interest?_ga=2.265198735.1076452750.1667738942-1138477334.1667738940"
          }}>{`Support for Mortgage Interest`}</a></h4>
      </li>
    </ul>
    <p>{`Check with your Local Authority website to see if you are eligible for Additional Housing Costs or Council Tax Support.`}</p>
    <h2>{`Assistance with Travel Costs`}</h2>
    <p>{`Check the UK Government website to see if you are eligible for `}<a parentName="p" {...{
        "href": "https://helpforhouseholds.campaign.gov.uk/help-with-transport-costs/"
      }}>{`Help with Transport Costs`}</a>{`.`}</p>
    <h2>{`Assistance with Food & Retails Costs`}</h2>
    <p>{`Supermarkets and Retailers are offering discounts and making offers see the link for details. For more information visit the UK Government website page on `}<a parentName="p" {...{
        "href": "https://helpforhouseholds.campaign.gov.uk/discounts-and-offers/"
      }}>{`Discounts and Offers from Retailers`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      